import { FormInstance } from 'antd/lib/form';
import { Auth } from 'aws-amplify';
import { IFeatureFlagsData } from 'context/mainContainerContext';
import {
  ALL,
  BusinessUnit,
  DateFormat,
  DecimalFormat,
  ModalTypes,
  ProductType,
  TimeZone,
  contractsTypes,
  convertValueWithConversionRate,
  getCurrency,
} from 'core/constants';
import { FeatureType, GridType, RequestStatus } from 'core/constants/statusAndState';
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import moment from 'moment-timezone';
import { Dispatch, SetStateAction } from 'react';
import { IFeatureFlags } from 'rolloutActions';
import { RoutePaths } from './history';
import { useGetContractDetails } from './services/countryOnboardingService';
import { AppConstants } from './utils/app.constants';
import { countryCodesFunc, countryDetailsFromStorage, getCountryCode } from './utils/countryUtils';
import { convertAmount } from './utils/currency.util';
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setToLocalStorage,
} from './utils/localStorage';
import { getFromSessionStorage, removeFromSessionStorage } from './utils/sessionStorage';

export interface FungicidesImpliedUsages {
  [ProductType.Fungicides]: number;
  [ProductType.Fungicides_Packs]: number;
}

export interface ITimeZone {
  id: string;
  label: string;
  name: string;
  formatted_label: string;
  countries: [];
}

export const isAccessTokenAvailable = () => !!getFromLocalStorage('tokens');
export const setAccessToken = (token: any) => setToLocalStorage('tokens', token);
export const getAccessToken = () => getFromLocalStorage('tokens') || '';
export const clearAccessToken = () => removeFromLocalStorage('tokens');

export const removeEmptyObjects = (array) =>
  array && array.length ? array.filter((obj) => !(obj && Object.keys(obj).length === 0)) : [];

export const getProgressByValues = (
  isPasswordValid: boolean,
  isMobileNumberValid: boolean,
  termsCheckbox: boolean
) => {
  let count = 1;
  if (isPasswordValid) {
    count++;
  }
  if (isMobileNumberValid) {
    count++;
  }
  if (termsCheckbox) {
    count++;
  }
  return count;
};

export const getLocale = () => {
  const CountryCodes = countryCodesFunc();

  const availableLocale = [
    {
      key: AppConstants.LANGUAGE_CODE.english,
      supportForCountry: CountryCodes.US,
    },
    {
      key:
        // getFromLocalStorage('i18nextLngTemp') ||
        // getFromLocalStorage('i18nextLng') ||
        AppConstants.LANGUAGE_CODE.russian,
      supportForCountry: CountryCodes.Russia,
    },
    {
      key:
        // getFromLocalStorage('i18nextLngTemp') ||
        // getFromLocalStorage('i18nextLng') ||
        AppConstants.LANGUAGE_CODE.spanish,
      supportForCountry: CountryCodes.Argentina,
    },
    {
      key: AppConstants.LANGUAGE_CODE.romanian,
      supportForCountry: CountryCodes.Romania,
    },
    {
      key: AppConstants.LANGUAGE_CODE.bulgarian,
      supportForCountry: CountryCodes.Bulgaria,
    },
    {
      key: AppConstants.LANGUAGE_CODE.hungarian,
      supportForCountry: CountryCodes.Hungary,
    },
    {
      key: AppConstants.LANGUAGE_CODE.polish,
      supportForCountry: CountryCodes.Poland,
    },
    {
      key: AppConstants.LANGUAGE_CODE.serbia,
      supportForCountry: CountryCodes.Serbia,
    },
    {
      key: AppConstants.LANGUAGE_CODE.german,
      supportForCountry: CountryCodes.Germany,
    },
  ];

  const country = countryDetailsFromStorage();

  const locale = availableLocale.filter((lang) => lang.supportForCountry == country.country_code);

  return locale.length > 0 ? locale[0]?.key : AppConstants.LANGUAGE_CODE.english;
};

export const isEmptyStr = (str?: string) => {
  if (str && str.length > 0) {
    return true;
  }
  return false;
};

export const getHeaders = () => ({
  common: {
    Authorization: `Bearer ${JSON.parse(getAccessToken()).access_token}`,
  },
});

export const getLangHeaders = () => {
  const lang = getLocale();
  return {
    common: {
      Authorization: `Bearer ${JSON.parse(getAccessToken()).access_token}`,
      'Accept-Language': lang,
    },
  };
};

export const getInitialName = (name: string) => {
  if (isEmpty(name)) {
    return '';
  }
  const splitedNames = name.split(' ');
  const firstName = first(splitedNames) || '';
  const lastName = last(splitedNames) || '';
  if (splitedNames.length >= 2) {
    return `${firstName.charAt(0).toUpperCase()} ${lastName.charAt(0).toUpperCase()}`;
  }
  return firstName.charAt(0).toUpperCase();
};

export const setTimeoutPromise = async (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

export const isSearchSubStringFound = (searchString = '', fields: string[] = []) =>
  !isEmpty(
    fields.filter((field) => (field || '').toLowerCase().indexOf(searchString.toLowerCase()) >= 0)
  );

export const CommaSeparatedList = (a: [], b: []) => {
  try {
    if (!b) {
      return a.join(', ');
    }
    return get(a, b).join(', ');
  } catch (e) {
    // do nothing
    return '';
  }
};

export const replaceDotWithComma = (
  number: string,
  decimalFormat: DecimalFormat = DecimalFormat.PointAsDecimal
) => {
  if (decimalFormat === DecimalFormat.PointAsDecimal || !number.includes('.')) return number;
  return number.replace(new RegExp('\\.', 'g'), ',');
};

export const formatCommaSeparatedAmount = (
  n,
  decimalFormat?: DecimalFormat,
  fractionDigits = true,
  minimumFractionDigits = 2
) => {
  if (typeof n === 'string') {
    if (isNaN(Number(n))) return n;
  }
  if (isNaN(n)) return n;

  let modifiedNf;
  if (fractionDigits) {
    const nf = new Intl.NumberFormat('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    modifiedNf = nf.format(n).replace(new RegExp('\\,', 'g'), ' ');
  } else {
    const nf = new Intl.NumberFormat('en', { minimumFractionDigits, maximumFractionDigits: 20 });
    modifiedNf = nf.format(n).replace(new RegExp('\\,', 'g'), ' ');
  }

  return replaceDotWithComma(modifiedNf, decimalFormat);
};

export const unformatCommaSeparatedAmount = (stringNumber) => {
  if (!stringNumber) {
    return stringNumber;
  }
  stringNumber = stringNumber.replace(new RegExp('\\ ', 'g'), ',');
  const thousandSeparator = Intl.NumberFormat()
    .format(11111)
    .replace(/\p{Number}/gu, '');
  const decimalSeparator = Intl.NumberFormat()
    .format(1.1)
    .replace(/\p{Number}/gu, '');

  return parseFloat(
    stringNumber
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), '.')
  );
};

export const formatLEListValues = (value, decimalFormat?: DecimalFormat) => {
  if (String(value)?.indexOf('$') > -1) {
    value = parseFloat(unformatCommaSeparatedAmount(value?.replace('$', '')));
  }
  return formatCommaSeparatedAmount(value, decimalFormat);
};

const statesToDisableButtons = [
  RequestStatus.Registration_Submitted,
  RequestStatus.Registration_Rejected,
  RequestStatus.Registration_Approved,
  RequestStatus.Agrobonus_Draft,
  RequestStatus.Agrobonus_Contract_Admin_Submitted,
  RequestStatus.Agrobonus_Contract_Admin_Approved,
  RequestStatus.Agrobonus_Contract_Admin_Rejected,
  RequestStatus.Growth_Draft,
  RequestStatus.Price_Fixation_Admin_Inprogress,

  RequestStatus.Growth_GRN_Admin_Submitted,
  RequestStatus.Growth_GRN_Admin_Approved,
  RequestStatus.Growth_Bonus_Agreement_Draft,
  RequestStatus.Growth_Bonus_Agreement_Submitted,
  RequestStatus.Growth_Bonus_Agreement_Approved,
  RequestStatus.Growth_GRN_Admin_Rejected,
  RequestStatus.Growth_Bonus_Agreement_Reject,
  RequestStatus.PRICE_Fixation_Draft,
  RequestStatus.Price_Fixation_Admin_Submitted,
  RequestStatus.Price_Fixation_Admin_Addendum_Draft,
  RequestStatus.Price_Fixation_Admin_System_Processed,
  RequestStatus.Price_Fixation_Grower_Addendum_Submitted,
  RequestStatus.Price_Fixation_Grower_Addendum_Approved,
  RequestStatus.Price_Fixation_Grower_Addendum_Rejected,
  RequestStatus.Growth_Bonus_Agreement_Downloaded,
];

export const showGRNHistoryRecords = (currentStatus) =>
  statesToDisableButtons.indexOf(currentStatus) > -1;

const statesToShowFixedPriceLegend = [
  RequestStatus.Price_Fixation_Admin_Submitted,
  RequestStatus.Price_Fixation_Admin_Addendum_Draft,
  RequestStatus.Price_Fixation_Grower_Addendum_Submitted,
  RequestStatus.Price_Fixation_Grower_Addendum_Approved,
  RequestStatus.Price_Fixation_Grower_Addendum_Rejected,
  RequestStatus.Agrobonus_Bonus_Agreement_Downloaded,
  RequestStatus.Addendum1_Downloaded,
  RequestStatus.Growth_Bonus_Agreement_Downloaded,
  RequestStatus.Price_Fixation_Admin_Inprogress,
  RequestStatus.Price_Fixation_Admin_System_Processed,
  RequestStatus.ADDENDUM2_DRAFT,
  RequestStatus.ADDENDUM2_SUBMITTED,
  RequestStatus.ADDENDUM2_REJECTED,
  RequestStatus.Price_Fixation_Grower_Addendum_Approved,
  RequestStatus.ADDENDUM2_APPROVED,
  RequestStatus.PAYOUT_PAYMENT_IN_PROGRESS,
  RequestStatus.PAYOUT_PAYMENT_COMPLETED,
  RequestStatus.PAYOUT_NO_PAYMENT,
];

export const showFixedPriceLegend = (currentStatus) =>
  statesToShowFixedPriceLegend.indexOf(currentStatus) > -1;

export const convertToMillion = (val) => val / 1000000;

export const showPayoutDetails = (currentStatus) => EligibleStatus.indexOf(currentStatus) > -1;

export const EligibleStatus = [
  RequestStatus.ADDENDUM2_APPROVED,
  RequestStatus.PAYOUT_PAYMENT_IN_PROGRESS,
  RequestStatus.PAYOUT_PAYMENT_COMPLETED,
  RequestStatus.PAYOUT_NO_PAYMENT,
];

export const capitalize = (str) => {
  const lowerCaseStr = str.toLowerCase();
  return lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1);
};

// For some countries we are using different time zone during campaign creation that's why we are returnnig value based on passed parameter
export const getTimeZone = (timeZone: string) => {
  try {
    const time_zone: ITimeZone = JSON.parse(timeZone);
    return time_zone?.name || 'Europe/Moscow';
  } catch (error) {
    const timeZoneDetails = getFromSessionStorage('timezone_details');
    if (timeZoneDetails?.length) {
      const zone = timeZoneDetails?.find((item) => item?.label === timeZone);
      return zone?.name || 'Europe/Moscow';
    }
    return 'Europe/Moscow';
  }
};

//For sending timezone value to BE we are using 'label' and for displaying on UI we are using 'formatted_label'
export const getTimeZoneLabel = (timeZone: string, type: string) => {
  try {
    const time_zone: ITimeZone = JSON.parse(timeZone);
    return time_zone?.[type] || 'Moscow Time';
  } catch (error) {
    const timeZoneDetails = getFromSessionStorage('timezone_details');
    const label = type === 'label' ? 'formatted_label' : 'label';
    if (timeZoneDetails?.length) {
      const zone = timeZoneDetails?.find((item) => item?.[label] === timeZone);
      return zone?.[type] || timeZone;
    }
    return type === 'label' ? 'MoscowTime' : 'Moscow Time';
  }
};

export const getDateFormat = (format: DateFormat) => {
  const DateFormatMapping = {
    [DateFormat.DDMMYYYY]: 'DD.MM.YYYY',
    [DateFormat.MMDDYYYY]: 'MM.DD.YYYY',
  };
  return DateFormatMapping[format] || DateFormatMapping[DateFormat.DDMMYYYY];
};

export enum TimeFormat {
  HrMin12 = 'hh:mm A',
  HrMin24 = 'HH:mm',
}

export const formatDate = (
  date: Date | string,
  dateFormat: DateFormat = DateFormat.DDMMYYYY,
  timeZone: TimeZone = TimeZone.MoscowTime,
  isNoTimeStamp?: boolean
): string | Date => {
  if (!date) return date;
  const momentDateFormat = getDateFormat(dateFormat);
  let momentDate = moment(date);
  if (!momentDate.isValid()) return '';
  if (timeZone) {
    if (isNoTimeStamp) {
      // If time stamp is T00:00:00 then no need to convert into UTC else it will take back to 1 day prior.
      // NOTE: CISLINK API send us invoice date in this format (2022-08-29T00:00:00) so it was creating problem at the time of displaying invoice date on UI (28-08-2022). so we have explicitly added this flag(isNoTimeStamp) for invoice date only & My Commodities graph.
      momentDate = moment(momentDate).tz(getTimeZone(timeZone), true);
    } else {
      // Added UTC to prevent conversion of dates coming from server.
      // Added true flag in tz function, to do not consider timestamp while converting dates into particular timezones(i.e campaign timezone).
      momentDate = moment.utc(momentDate).tz(getTimeZone(timeZone), true);
    }
  }
  return momentDate.format(momentDateFormat);
};

export const formatTime = (
  date: Date | string,
  timeFormat: TimeFormat = TimeFormat.HrMin24,
  timeZone?: TimeZone
): string | Date => {
  if (!date) return date;
  let momentDate = moment(date);
  if (!momentDate.isValid()) return '';
  if (timeZone) {
    momentDate = momentDate.tz(getTimeZone(timeZone));
  }
  return momentDate.format(timeFormat);
};

export const statesToEnableAllowBonusAgreement = [
  RequestStatus.Registration_Approved, // Nowonwards we will not be using Growth_GRN_Draft, instead we wil be using Registration_Approved status from FE & BE side.
  RequestStatus.Growth_Bonus_Agreement_Reject,
];
export const statusToShowMessageAllowAgreement = [
  RequestStatus.Registration_Approved,
  RequestStatus.Registration_Rejected,
  RequestStatus.Registration_Submitted,
  RequestStatus.GROWTH_BANK_DETAILS,
  RequestStatus.GROWTH_TIN_DETAILS,
  RequestStatus.GROWTH_AUTHORIZATION_RIGHTS,
  RequestStatus.Growth_Draft,
  RequestStatus.Growth_GRN_Draft,
];

export const enableAllowBonusAgreement = (currentStatus) =>
  statesToEnableAllowBonusAgreement.indexOf(currentStatus) > -1;

export const showBonusAgreementAmount = (currentStatus) =>
  statesToEnableAllowBonusAgreement.indexOf(currentStatus) <= -1;

export const getFilterCampaignWithCropYear = (selectedCampaign, selectedCropYear) =>
  //FILTER CAMPAIGN WITH SELECTED CROP YEARS
  selectedCampaign.filter((s) => s.crop_year === selectedCropYear);

export const objCropFun = (selectedCampaign, selectedCropYear, t) => {
  const objCrop = {};
  if (selectedCampaign && selectedCampaign?.length > 0) {
    //FILTER CAMPAIGN WITH SELECTED CROP YEAR
    const selectedCropYearSeasonData = getFilterCampaignWithCropYear(
      selectedCampaign,
      selectedCropYear
    );

    for (const campaignSettings of selectedCropYearSeasonData) {
      const {
        id,
        date_format,
        time_zone,
        is_active,
        comodity_exchange_indexes,
        crop,
        price_fix_start_date,
        price_fix_last_date,
        european_date,
        payout_start_date,
        payout_end_date,
      } = campaignSettings;
      const cropKey = `${t(crop.name)} ${t(comodity_exchange_indexes?.[0]?.exchange)} ${t(
        comodity_exchange_indexes?.[0]?.maturity
      )} ${t(comodity_exchange_indexes?.[0]?.future_year)} ${t(
        formatDate(price_fix_start_date, date_format, time_zone)
      )} ${t(formatDate(price_fix_last_date, date_format, time_zone))} ${t(
        formatDate(european_date, date_format, time_zone)
      )} ${t(formatDate(payout_start_date, date_format, time_zone))} ${t(
        formatDate(payout_end_date, date_format, time_zone)
      )}`;

      if (objCrop[cropKey]) {
        if (is_active) {
          objCrop[cropKey].seasonIds.unshift(id);
          objCrop[cropKey] = {
            cropData: {
              exchange: comodity_exchange_indexes?.[0]?.exchange,
              crop_id: crop.id,
              future_year: comodity_exchange_indexes?.[0]?.future_year,
              maturity: comodity_exchange_indexes?.[0]?.maturity,
              crop_name: crop.name,
              price_fix_start_date:
                price_fix_start_date && formatDate(price_fix_start_date, date_format, time_zone),
              price_fix_last_date:
                price_fix_last_date && formatDate(price_fix_last_date, date_format, time_zone),
              european_date: european_date && formatDate(european_date, date_format, time_zone),
              payout_start_date:
                payout_start_date && formatDate(payout_start_date, date_format, time_zone),
              payout_end_date:
                payout_end_date && formatDate(payout_end_date, date_format, time_zone),
            },
            seasonIds: [id],
            campaignSettings: campaignSettings,
          };
        } else {
          objCrop[cropKey].seasonIds.push(id);
        }
      } else {
        objCrop[cropKey] = {
          cropData: {
            exchange: comodity_exchange_indexes?.[0]?.exchange,
            crop_id: crop.id,
            future_year: comodity_exchange_indexes?.[0]?.future_year,
            maturity: comodity_exchange_indexes?.[0]?.maturity,
            crop_name: crop.name,
            price_fix_start_date:
              price_fix_start_date && formatDate(price_fix_start_date, date_format, time_zone),
            price_fix_last_date:
              price_fix_last_date && formatDate(price_fix_last_date, date_format, time_zone),
            european_date: european_date && formatDate(european_date, date_format, time_zone),
            payout_start_date:
              payout_start_date && formatDate(payout_start_date, date_format, time_zone),
            payout_end_date: payout_end_date && formatDate(payout_end_date, date_format, time_zone),
          },
          seasonIds: [id],
          campaignSettings: campaignSettings,
        };
      }
    }
    return objCrop;
  }
  return;
};

export const checkIfFeatureAvailable = (features: any, gridType: string) => {
  let ifExist = '';
  switch (gridType) {
    case GridType.FINAL_PAYOUT_V2_GRID:
      ifExist = features?.find((item) => item?.name === FeatureType.FINAL_PAYOUT_V2_BUTTON);
      return ifExist ? true : false;

    case GridType.PAYOUT_FINAL:
      ifExist = features?.find(
        (item) => item?.name === FeatureType.FINAL_PAYOUT_GENERATE_ADDENDUM_BUTTON
      );
      return ifExist ? true : false;

    default:
      return false;
  }
};

export const getMaxUSMobileNumberValue = (checkMobileNumber) => {
  if (checkMobileNumber) return 25;
};

export const validatePhoneNumber = (phoneNumberString) => {
  if (phoneNumberString !== '' && phoneNumberString !== undefined) {
    if (phoneNumberString.length == 1 && phoneNumberString.includes('+')) return false;
  }
  return true;
};

export const filterCampaignsForUSSSR = (campaigns, featuresData) => {
  let filteredSeasons = [] as any;
  if (
    featuresData?.data?.user?.commercial_unit == ALL &&
    featuresData?.data?.user?.sales_area == ALL
  ) {
    filteredSeasons = campaigns;
  } else if (
    featuresData?.data?.user?.commercial_unit !== ALL &&
    featuresData?.data?.user?.sales_area == ALL
  ) {
    filteredSeasons = campaigns.filter(
      (camp) =>
        camp?.commercial_unit == null ||
        camp?.commercial_unit?.name == featuresData?.data?.user?.commercial_unit ||
        camp?.commercial_unit?.name == ALL
    );
  } else if (
    featuresData?.data?.user?.commercial_unit !== ALL &&
    featuresData?.data?.user?.sales_area !== ALL
  ) {
    filteredSeasons = campaigns.filter(
      (camp) =>
        camp?.commercial_unit == null ||
        camp?.sales_area?.name == featuresData?.data?.user?.sales_area ||
        (camp?.commercial_unit?.name == featuresData?.data?.user?.commercial_unit &&
          camp?.sales_area?.name == ALL) ||
        camp?.commercial_unit?.name == ALL
    );
  }

  return filteredSeasons;
};

export const removeExtraSpaces = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(removeExtraSpaces);
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, removeExtraSpaces(value)])
    );
  } else if (typeof obj === 'string') {
    return obj.replace(/\s+/g, ' ').trim();
  }
  return obj;
};

interface IproductUsageComparisionLogic {
  productForm: any;
  unitOfMeasure: string;
  campaignId?: string;
  t: any;
  isActual?: boolean;
}
/**
 *  This logic is execute for feature flag ProductUsageComparison
 *  Logic: Total Implied usage of Fugicides products should match Total Implied usage of Herbicides products
 *         Total Implied usage of Fugicides products should match Total Implied usage of Biostimulants products // Deprecated (CHZU-3659)
 */
export const productUsageComparisionLogic = ({
  productForm,
  unitOfMeasure,
  campaignId,
  t,
  isActual = false,
}: IproductUsageComparisionLogic) => {
  const formValues = productForm.getFieldsValue();
  //CHZU-3659 Removed Biostimulant from comparison logic due to new requirement
  const prodcutUsageComparision: any = {
    [ProductType.Fungicides]: 0,
    [ProductType.Herbicides]: 0,
  };
  const errorMsgs: any = [];
  const inputFieldDetailsToHighlight: any = [];

  Object.keys(formValues).forEach((formKey) => {
    Object.keys(prodcutUsageComparision).forEach((productTypeKey) => {
      if (
        !isActual && campaignId
          ? formKey.startsWith(`usage-CP-${productTypeKey}`) && formKey.endsWith(campaignId)
          : formKey.startsWith(`impliedUsage-CP-${productTypeKey}`)
      ) {
        if (productForm.getFieldValue(formKey)) {
          prodcutUsageComparision[productTypeKey] += Number(productForm.getFieldValue(formKey));
        }
      }
    });
  });

  const errorFormations = (type) => {
    if (
      prodcutUsageComparision[ProductType.Fungicides] > 0 &&
      prodcutUsageComparision[type] > 0 &&
      prodcutUsageComparision[ProductType.Fungicides] !== prodcutUsageComparision[type]
    ) {
      errorMsgs.push(
        `${t(`The total hectares of ${type}`)} (${prodcutUsageComparision[type]} ${t(
          `${unitOfMeasure}`
        )}) ${t('should match with the total hectares of Fungicides')} (${
          prodcutUsageComparision[ProductType.Fungicides]
        } ${t(`${unitOfMeasure}`)})`
      );
      inputFieldDetailsToHighlight.push({
        campaignId,
        productCategory: type,
      });
    }
  };

  Object.keys(prodcutUsageComparision).forEach((key) => {
    if (key !== ProductType.Fungicides) {
      errorFormations(key);
    }
  });

  return { errorMsgs, inputFieldDetailsToHighlight };
};

export const filterFormFieldsWithParameters = ({
  errorFocusInputFields,
  record,
  isActual = false,
}) =>
  errorFocusInputFields.filter((item) =>
    isActual
      ? item.productCategory === record
      : item.campaignId === record?.campaignId && item.productCategory === record?.type
  );

interface IprotectedPurchasesCardValue {
  modalType: string;
  IsExchangeRate: boolean;
  purchasesAmount: number;
  exchangeRate: string;
  currancyOption: string;
  currancyPurchases: string;
  isConverted?: boolean;
}
export const protectedPurchasesCardValue = ({
  IsExchangeRate,
  purchasesAmount,
  exchangeRate,
  modalType,
  currancyOption,
  currancyPurchases,
  isConverted,
}: IprotectedPurchasesCardValue) => {
  let value;
  let unit;
  if (IsExchangeRate && modalType.includes(ModalTypes.US_MODAL)) {
    if (isConverted) {
      // BE is converting ESTIMATED_RISK_PROTECTION_PAYOUT amount for all the US modal country, So we don't need convert at FE
      value = purchasesAmount ?? 0;
    } else {
      // BE is not converting RISK_PROECTION_PURCHASE amount for all the US modal country, so we need to convert at FE
      value = convertAmount(purchasesAmount, Number(exchangeRate)) ?? 0;
    }
    unit = currancyOption;
  } else if (IsExchangeRate && modalType.includes(ModalTypes.RU_MODAL)) {
    // In Case RU Modal We need to convert amount as BE not sending converted values
    value = convertAmount(purchasesAmount, Number(exchangeRate)) ?? 0;
    unit = currancyOption;
  } else {
    // Left only US Country in that amount no need to covert as both currencyOption and CurrencyPurchases are same
    value = purchasesAmount ?? 0;
    unit = currancyOption;
  }

  return {
    value,
    unit,
  };
};

export const rearrangeProducts = (sgProducts) => {
  const mandatory = {};
  const optional = {};

  Object.keys(sgProducts)
    .sort()
    .forEach((keyName) => {
      if (sgProducts[keyName].mandatory == true) mandatory[keyName] = sgProducts[keyName];
      else optional[keyName] = sgProducts[keyName];
    });

  const allProducts = { ...mandatory, ...optional };
  return allProducts;
};

export const isUnitConversionNeeded = (
  pricePerUnitUnderlyingIndex: string,
  pricePerUnitConversion: string
) => (pricePerUnitConversion === pricePerUnitUnderlyingIndex ? false : true);

export const displayValueWithOrWithoutUnitConversion = (
  pricePerUnitUnderlyingIndex: string,
  pricePerUnitConversion: string,
  value: number,
  cropName: string
) =>
  isUnitConversionNeeded(pricePerUnitUnderlyingIndex, pricePerUnitConversion)
    ? convertValueWithConversionRate(value, cropName)
    : value;

// for checking required status is present or not
export const isStatusPresent = (requestStatus: any) =>
  [
    RequestStatus.PAYOUT_COMPLETED,
    RequestStatus.NO_PAYOUT,
    RequestStatus.PAYOUT,
    RequestStatus.ADDENDUM2_DRAFT,
    RequestStatus.ADDENDUM2_DOWNLOADED,
    RequestStatus.ADDENDUM2_SUBMITTED,
    RequestStatus.ADDENDUM2_APPROVED,
    RequestStatus.ADDENDUM2_REJECTED,
  ].includes(requestStatus);

export async function userSignOut() {
  await Auth.signOut({ global: true });
  window.location.href = RoutePaths.HOME();
  removeFromLocalStorage('country_details');
  removeFromLocalStorage('onboarded_country_codes');
  removeFromLocalStorage('onboarded_country_code_urls');
  removeFromLocalStorage('onboarded_country_currency');
  removeFromLocalStorage('userRole');
  removeFromSessionStorage(AppConstants.ROLLOUT_CACHE_KEY);
  removeFromSessionStorage(AppConstants.FEATURE_FLAG_CACHE_KEY);
  priceFixationRouteClick();
}

export function priceFixationRouteClick() {
  removeFromLocalStorage('activeTab');
  removeFromLocalStorage('activeTabIndex');
}

export const extractVolumes = (displayProducts) => {
  const cpVolumes: number[] = [];
  const seVolumes: number[] = [];

  for (const key in displayProducts) {
    for (const subKey in displayProducts[key]) {
      for (const subKey2 in displayProducts[key][subKey]) {
        if (subKey === BusinessUnit.CP) {
          displayProducts[key][subKey][subKey2]?.forEach((item) => {
            if (item?.volume) {
              cpVolumes.push(Number(item?.volume));
            }
          });
        } else if (subKey === BusinessUnit.SE) {
          displayProducts[key][subKey][subKey2]?.forEach((item) => {
            if (item?.volume) {
              seVolumes.push(Number(item?.volume));
            }
          });
        }
      }
    }
  }

  return { cpVolumes, seVolumes };
};

/*
  ITERATE OVER FORM INPUT VALUE
  CHECK FOR USAGE INPUT FIELD
  AND STORED THAT VALUE INTO ARRAY.
  USAGE INPUT FIELD NAME IS AS FOLLOW `usage-${businessUnit}-${category}-${i}-${campaign.id}`
*/
export const getFungicidesImpliedUsages = (
  formValuesObj,
  businessUnit: string,
  campaignId: string
) =>
  Object.entries(formValuesObj).reduce<FungicidesImpliedUsages>(
    (acc, [key, value]) => {
      if (!value) return acc;
      if (
        key.startsWith(`usage-${businessUnit}-${ProductType.Fungicides}-`) &&
        key.endsWith(campaignId)
      ) {
        acc[ProductType.Fungicides] += Number(value);
      }
      if (
        key.startsWith(`usage-${businessUnit}-${ProductType.Fungicides_Packs}-`) &&
        key.endsWith(campaignId)
      ) {
        acc[ProductType.Fungicides_Packs] += Number(value);
      }
      return acc;
    },
    {
      [ProductType.Fungicides]: 0,
      [ProductType.Fungicides_Packs]: 0,
    }
  );

export const getImpliedUsagesArray = (formValuesObj, businessUnit, campaignId): any[] => {
  const impliedUsagesArray: any = [];
  Object.entries(formValuesObj).forEach(([key, value]) => {
    if (key.startsWith(`usage-${businessUnit}`) && key.endsWith(campaignId)) {
      if (typeof value !== 'undefined') {
        impliedUsagesArray.push(value);
      }
    }
  });
  return impliedUsagesArray;
};

//If Business Unit is SE then we will add all the Implied Usage Volume/usage else for CP we will just show the highest hectares from Implied Usage Volume/usage
export const getCommittedArea = (
  businessUnit,
  campaignId,
  formValuesObj,
  featureFlags: IFeatureFlags
) => {
  if (businessUnit === BusinessUnit.CP && featureFlags?.ProductCommitmentsUsageLimit) {
    const fungicidesImpliedUsages = getFungicidesImpliedUsages(
      formValuesObj,
      businessUnit,
      campaignId
    );
    const fungicides = fungicidesImpliedUsages[ProductType.Fungicides];
    const fungicidesPacks = fungicidesImpliedUsages[ProductType.Fungicides_Packs];
    const cpArea =
      fungicides > 0 && fungicidesPacks > 0
        ? Math.min(fungicides, fungicidesPacks)
        : Math.max(fungicides, fungicidesPacks);

    formValuesObj[`${businessUnit}Area-${campaignId}`] = cpArea;
  } else {
    const impliedUsagesArray = getImpliedUsagesArray(formValuesObj, businessUnit, campaignId);
    if (businessUnit === BusinessUnit.CP) {
      formValuesObj[`${businessUnit}Area-${campaignId}`] = Math.max(
        impliedUsagesArray?.length === 0 ? 0 : Math.max(...impliedUsagesArray)
      );
    } else {
      const sum = impliedUsagesArray.reduce((acc, value) => acc + value, 0);
      formValuesObj[`${businessUnit}Area-${campaignId}`] = sum;
    }
  }

  return formValuesObj;
};

// Calculate maximum limit (liters) for Elatus Era product
export const calculateElatusEraMaxLimit = (
  products: any[],
  campaignId: string,
  businessUnit: string,
  setElatusEraMaxLimitVolumeErrorMessage: Dispatch<SetStateAction<any>>,
  productForm: FormInstance<any>,
  t
) => {
  const errors: any[] = [];
  const formValues = productForm.getFieldsValue();

  const selectedProducts = removeEmptyObjects(
    products.filter((p) => p.type === ProductType.Fungicides)
  );

  const fungicidePacksUsage = Object.entries(formValues).reduce((acc, [key, value]) => {
    if (
      key.startsWith(`usage-${businessUnit}-${ProductType.Fungicides_Packs}-`) &&
      key.endsWith(campaignId)
    ) {
      return acc + (Number(value) || 0);
    }
    return acc;
  }, 0);

  if (selectedProducts.length > 0 && fungicidePacksUsage > 0) {
    selectedProducts.map((product, i) => {
      const usage = formValues[`volume-${product?.type}-${i}-${campaignId}`];
      const packsUsage = fungicidePacksUsage * product?.usage;
      if (usage > packsUsage) {
        const elatusProductError = {
          product: product?.product_brand,
          errMessage: `${t('Max limit volume for')} ${product?.product_brand} ${t(
            'considered is'
          )} ${Math.round(packsUsage)} ${t(product?.uom)}`,
        };
        errors.push(elatusProductError);
      }
    });
  }
  setElatusEraMaxLimitVolumeErrorMessage(errors);
};

// Function for calculating max hectares (Romania).
export const setMaximumHectares = (
  newProducts: any[],
  setVolumeCap: Dispatch<SetStateAction<number>>
) => {
  const hectaresForEachUnit: { [x: string]: number } = newProducts.reduce((acc, product) => {
    if (product.type === ProductType.Fungicides || product.type === ProductType.Fungicides_Packs) {
      const productUnit = product?.uom;
      const quantityOfHectares = parseFloat(product?.volume) / product?.usage;
      acc[productUnit]
        ? (acc[productUnit] += quantityOfHectares)
        : (acc[productUnit] = quantityOfHectares);
    }
    return acc;
  }, {});

  const minHectare = Math.min(...Object.values(hectaresForEachUnit));

  setVolumeCap(minHectare);
  return minHectare;
};

export const GetContractsDocuments = (countryId: string) => {
  const { isFetching: fetchingContractDetails, data: contractDetails } = useGetContractDetails(
    countryId,
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(countryId),
    }
  );

  // Find the object with doc_type 'Terms_And_Conditions'
  const termsAndConditionsObj = contractDetails?.data.find(
    (item) => item.doc_type === contractsTypes.TERMS_AND_CONDITIONS
  );
  let termsAndConditionsPath;
  if (termsAndConditionsObj) {
    ({ path: termsAndConditionsPath } = termsAndConditionsObj);
  }

  // Find the object with doc_type 'Program_Terms'
  const programTermsObj = contractDetails?.data.find(
    (item) => item.doc_type === contractsTypes.PROGRAM_TERMS
  );
  let programTermsPath;
  if (programTermsObj) {
    ({ path: programTermsPath } = programTermsObj);
  }

  // Find the object with doc_type 'Privacy_Policy'
  const privacyPolicyObj = contractDetails?.data.find(
    (item) => item.doc_type === contractsTypes.PRIVACY_POLICY
  );
  let privacyPolicyPath;
  if (privacyPolicyObj) {
    ({ path: privacyPolicyPath } = privacyPolicyObj);
  }

  return {
    fetchingContractDetails,
    privacyPolicyPath,
    programTermsPath,
    termsAndConditionsPath,
    contractDetails,
  };
};

const displayUnitsForSECampaign = (type) => {
  if (type) {
    return 'RUB';
  }
  return 'UNITS';
};

export const getUnits = (growthTargetType, currencyPurchase, type = false) =>
  growthTargetType === 'VOLUME_BASED'
    ? displayUnitsForSECampaign(type)
    : getCurrency(currencyPurchase, 'Unit');
export const getBonusAgreementAmount = (growthTargetType, protectionPrice, protectionVolume) =>
  growthTargetType === 'VOLUME_BASED' ? protectionVolume : protectionPrice;

export const getCurrentTime = (): { hours: number; minutes: number } => {
  const currentDate = new Date();
  const currentHours = currentDate.getHours();
  const currentMinutes = currentDate.getMinutes();
  return { hours: currentHours, minutes: currentMinutes };
};

export enum FileStatus {
  IN_PROGRESS = 'IN_PROGRESS',
}

export const roundOffValue = (value: number | string): string => {
  const numberValue = Number(value);
  if (!isNaN(numberValue)) {
    return Math.round(numberValue).toLocaleString('en').replace(/,/g, ' ');
  }
  return value.toString();
};

export const handleUpload = async ({ onSuccess, onError, file }: any) => {
  const presignedPostData = file?.data;
  const formData = new FormData();
  if (!presignedPostData) {
    return;
  }
  Object.keys(presignedPostData?.fields).forEach((key) => {
    formData.append(key, presignedPostData?.fields?.[key]);
  });
  formData.append('file', file);
  try {
    const response = await fetch(presignedPostData?.url, {
      method: 'POST',
      body: formData,
    });
    if (response?.status === 204) {
      onSuccess(file);
    } else {
      onError(response?.statusText, JSON.stringify(response), file);
    }
  } catch (error) {
    console.log(`signed url error : ${error}`);
  }
};

//This method will return false for Poland, Bulgaria, Germany and for other true
//Todo: We need to remove this function and use rollout effectively
/**
 * Determines if the payee name is required based on the country code.
 *
 * @returns {boolean} - Returns `false` if the country code is 'PL' or 'BG', otherwise returns `true`.
 */
export const isPayeeNameRequired = () => {
  const countryCode = getCountryCode();
  return ['PL', 'BG', 'DE'].includes(countryCode) ? false : true;
};

export const getUserRole = (callbackFn) =>
  callbackFn(getFromLocalStorage('userRole'))
    ? getFromLocalStorage('userRole').toLowerCase()
    : null;

export const accessForUsers = (userRole) => {
  const { ROLES } = AppConstants;
  const roles = [ROLES.ADMIN, ROLES.retailerHeadshed, ROLES.SSR, ROLES.rsr];
  return roles?.includes(userRole);
};

export const accessForAdminHeadShed = (userRole) => {
  const { ROLES } = AppConstants;
  const roles = [ROLES.ADMIN, ROLES.retailerHeadshed];
  return roles?.includes(userRole);
};

export const accessForSSRRSR = (userRole) => {
  const { ROLES } = AppConstants;
  const roles = [ROLES.SSR, ROLES.rsr];
  return roles?.includes(userRole);
};

export const getRetailerNameFromMapper = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }

  return data.map((item) => {
    const retailerName = item?.retailer_mapper?.retailer?.name;
    return { retailer: retailerName, ...item };
  });
};

export const retrieveUserByCreator = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }

  return data.map((item) => {
    const registered_by = item?.enrolled_by?.name;
    return { registered_by, ...item };
  });
};
//
export const checkLinkExpiry = (expiredTime: any) => {
  const currentTime = Date.now();

  if (expiredTime && currentTime > expiredTime) {
    return false; // Link has expired
  }
  return true; // Link is still valid
};

//To Check customer is rejected or not
export const isCustomerRejected = (modalType: string, status: RequestStatus) =>
  modalType?.includes(ModalTypes.US_MODAL) && status?.includes(RequestStatus.REJECTED);

export const formatUserName = (name: string) => {
  if (name) {
    const formattedName = name?.charAt(0).toUpperCase() + name?.slice(1).toLowerCase();
    return formattedName?.replace('_', ' ');
  }
  return '';
};

export const handleDefaultOption = (val: string, setValue: (val) => void) => {
  setValue(val !== 'default' ? val : '');
};

export const getValueForPayout = (
  isRevisedActual: boolean,
  payoutValue: string | number | undefined,
  t: (val) => void,
  decimalFormat: DecimalFormat,
  fractionDigits: boolean = true
) => {
  if (isRevisedActual) {
    return formatCommaSeparatedAmount(payoutValue, decimalFormat, fractionDigits);
  }
  return t('N/A');
};

/**
 * The function `extractCropYear` takes a string representing a crop year and
 * extracts the last two digits to return a full four-digit crop year.
 * @param {string} cropYear - cropYear: a string representing the crop year in the
 * format "2023-24"
 * @returns '2024
 */
export const extractCropYear = (cropYear: string) =>
  cropYear.substring(0, 2) + cropYear.substring(5, 7);

/**
 * The function `updateKeyInObject` updates a key in an object with a new value if
 * the key does not already exist in the object.
 * @param {Object} object - The `object` parameter is an object that you want to
 * update with a new key-value pair.
 * @param {string} key - The `key` parameter in the `updateKeyInObject` function is
 * a string that represents the key you want to update or add in the object.
 * @param {string | null} value - The `value` parameter in the `updateKeyInObject`
 * function is the new value that you want to assign to the specified `key` in the
 * `object`. It can be a string or `null`.
 */
export const updateKeyInObject = (object: Object, key: string, value: string | null) => {
  if (!(key in object)) {
    object[key] = value;
  }
};

export const formatMobileNumber = (phoneNumber: string) => phoneNumber?.replace(/[^+\d]/g, '');

export const fetchRequiredFlag = (featureFlagData: IFeatureFlagsData[], flagCode: string) =>
  featureFlagData?.find((flag) => flag.index_code === flagCode)?.condition;

export const getUniqueValues = (data, key) => {
  const uniqueSet = new Set();

  for (const item of data) {
    const value = item[key];
    if (value) uniqueSet.add(value);
  }

  return Array.from(uniqueSet);
};

//To check if requestStatus is before bonus agreement approved
export const isBeforeBonusAgreementApproved = (status: RequestStatus) =>
  [
    RequestStatus.Registration_Draft,
    RequestStatus.Registration_Submitted,
    RequestStatus.Registration_Rejected,
    RequestStatus.Registration_Approved,
    RequestStatus.Growth_GRN_Admin_Submitted,
    RequestStatus.Growth_GRN_Admin_Rejected,
    RequestStatus.Growth_GRN_Admin_Approved,
    RequestStatus.GROWTH_AUTHORIZATION_RIGHTS,
    RequestStatus.GROWTH_TIN_DETAILS,
    RequestStatus.GROWTH_BANK_DETAILS,
    RequestStatus.Statistical_Form_Draft,
    RequestStatus.Growth_Bonus_Agreement_Draft,
    RequestStatus.Growth_Bonus_Agreement_Downloaded,
    RequestStatus.Growth_Bonus_Agreement_Submitted,
    RequestStatus.Growth_Bonus_Agreement_Reject,
  ].includes(status);

export const isEndDatePassed = (date: Date, timeZone: 'string') =>
  moment().tz(timeZone).isAfter(date);

/**
 * Checks if the sidebar menu should be enabled based on the current path and a list of routes.
 *
 * @param currentPath - The current path of the application.
 * @param routes - An array of route strings to check against the current path.
 * @returns A boolean indicating whether the sidebar menu should be enabled.
 */
export const isSideBarMenuEnabled = (currentPath: string, routes: string[]) =>
  routes.some((route) => currentPath.includes(route));
